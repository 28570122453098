<template>
  <div class="p-5 d-flex justify-content-center align-items-center">
    <!-- <h1>Custom Email Handler</h1> -->
    <moon-loader
      v-if="this.$router.currentRoute.name === 'CustomEmailHandler'"
      color="#F26000"></moon-loader>

    <router-view/>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';

export default {
  name: 'CustomEmailHandler',
  created() {
    const {
      mode, oobCode, apiKey, continueUrl, lang,
    } = this.$route.query;

    let routeName;
    switch (mode) {
      case 'resetPassword':
        routeName = 'ResetPassword';
        break;
      case 'recoverEmail':
        routeName = 'RecoverEmail';
        break;
      case 'verifyEmail':
        routeName = 'VerifyEmail';
        break;
      default:
        console.log('Invalid Mode');
        break;
    }

    this.$router.push({
      name: routeName,
      params: {
        oobCode,
        apiKey,
        continueUrl,
        lang,
      },
    });
  },
  methods: {
  },
  components: {
    MoonLoader,
  },
};
</script>

/* eslint-disable object-shorthand */
import constants from '@/constants';
import axios from 'axios';

export default {
  signIn(idToken) {
    let baseUrl;
    if (window.location.href.includes('localhost')) {
      baseUrl = constants.DEV_FIREBASE_FUNCTIONS_BASE_URL;
    } else {
      baseUrl = constants.FIREBASE_FUNCTIONS_BASE_URL;
    }

    const body = {
      idToken: idToken,
    };
    return axios
      .post(
        `${baseUrl}/usersSignIn`,
        body,
        { withCredentials: true },
      );
  },
  checkAuthStatus(token) {
    let baseUrl;
    if (window.location.href.includes('localhost')) {
      baseUrl = constants.DEV_FIREBASE_FUNCTIONS_BASE_URL;
    } else {
      baseUrl = constants.FIREBASE_FUNCTIONS_BASE_URL;
    }

    return axios
      .post(
        `${baseUrl}/usersCheckAuthStatus`,
        { },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        },
      );
  },
};

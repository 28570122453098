<template>
  <div class="p-5">
    <h3 class="mb-4"><strong>Wahctwoord vergeten</strong></h3>

    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group>
        <b-form-input
          name="email"
          v-model="$v.form.email.$model"
          :state="validateState('email')"
          aria-describedby="email-feedback"
          placeholder="Email"
          type="email"
          autocomplete="email"
        ></b-form-input>

        <b-form-invalid-feedback id="email-feedback">
          Dit veld moet een geldig email adress bevatten
        </b-form-invalid-feedback>
      </b-form-group>

      <button class="blue-button w-100" type="submit">
        Resetten
      </button>
      <button class="text-only-button w-100 mt-3" type="button" @click="cancel()">
        Annuleren
      </button>
    </b-form>
  </div>
</template>

<script>
import {
  required, email,
} from 'vuelidate/lib/validators';
import { auth } from '@/firebase';

export default {
  name: 'PasswordForgotten',
  data: () => {
    return {
      form: {
        email: '',
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else {
        auth.sendPasswordResetEmail(this.form.email).then(() => {
          this.$bvToast.toast('Er is een mail verstuurd om het wachtwoord te resetten', {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'info',
            solid: true,
            appendToast: false,
          });
          this.$router.push({ name: 'Login' });
        })
          .catch((error) => {
            console.log(`[Password reset ] ${error}`);
          });
      }
    },
    cancel() {
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

import userInformationAPI from '@/api/userInformation';
import processUserSignIn from '@/helpers/processUserSignIn';

export default {
  provideAdditionalInfoOrReturnToApp(uid, context) {
    if (uid === undefined) return;

    userInformationAPI.get(uid)
      .then((snapshot) => {
        if (snapshot.empty) {
          context.$store.commit('stoppedLoading');
          context.$router.push({ name: 'AdditionalUserInformation', params: { userid: uid } });
        } else {
          processUserSignIn
            .processUserSignIn(context);
        }
      }).catch(() => {
        context.$store.commit('stoppedLoading');
        context.$bvToast.toast('Er is iets fout gegaan', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      });
  },
};

<template>
  <div class="p-5">
    <h3><strong>Inloggen met Mijn Kieswijs</strong></h3>

    <b-form @submit.stop.prevent="signInWithEmail" class="d-flex flex-column mt-5">
      <b-input-group>
        <b-input-group-prepend>
          <span class="input-group-text"><b-icon-at></b-icon-at></span>
        </b-input-group-prepend>
          <b-form-input
            class="LoginInput"
            type="email"
            placeholder="Email"
            v-model="email">
        </b-form-input>
      </b-input-group>

      <b-input-group class="mt-3">
        <b-input-group-prepend>
          <span class="input-group-text"><b-icon-shield-lock></b-icon-shield-lock></span>
        </b-input-group-prepend>
          <b-form-input
            class="LoginInput"
            type="password"
            placeholder="Wachtwoord"
            v-model="password">
        </b-form-input>
      </b-input-group>

      <button class="blue-button mt-3" type="sumbit">
        Inloggen
      </button>
      <button class="sign-in-with-google-button mt-3" @click="signInWithGoogle()" type="button">
        <b-icon-google></b-icon-google> Inloggen met Google
      </button>
      <button class="text-only-button mt-3" @click="forgotPassword()" type="button">
        Wachtwoord vergeten
      </button>

      <hr class="my-4"/>
      <button class="sign-up-button" @click="signUp()" type="button">
        Aanmelden
      </button>
      <!-- <button class="mt-3 sign-up-button" type="button" @click="signOut()">SignOut</button> -->
    </b-form>
  </div>
</template>

<script>
import { auth, firebaseAuth } from '@/firebase';
import provideAdditionalInfoOrReturnToApp from '@/helpers/provideAdditionalInfoOrReturnToApp';

export default {
  name: 'Login',
  data: () => {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    signInWithEmail() {
      this.$store.commit('loading');
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then((data) => {
          provideAdditionalInfoOrReturnToApp
            .provideAdditionalInfoOrReturnToApp(data.user.uid, this);
        })
        .catch(() => {
          this.$store.commit('stoppedLoading');
          this.$bvToast.toast('Email adress of Wachtwoord incorrect ', {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false,
          });
        });
    },
    signInWithGoogle() {
      this.$store.commit('loading');
      const provider = new firebaseAuth.GoogleAuthProvider();
      auth.signInWithPopup(provider)
        .then((data) => {
          provideAdditionalInfoOrReturnToApp
            .provideAdditionalInfoOrReturnToApp(data.user.uid, this);
        })
        .catch(() => {
          this.$store.commit('stoppedLoading');
          this.$bvToast.toast('Er is iets fout gegaan tijdens het inloggen met Google', {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false,
          });
        });
    },
    signUp() {
      this.$router.push({ name: 'SignUp' });
    },
    forgotPassword() {
      this.$router.push({ name: 'PasswordForgotten' });
    },
  },
};
</script>

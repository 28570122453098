import { userInformationCollection } from '@/firebase';
import userInformationConverter from '../models/converters/userInformation';

export default {
  get(uid) {
    return userInformationCollection
      .where('uid', '==', uid)
      .withConverter(userInformationConverter.userInformationConverter)
      .get();
  },
  create(data) {
    return userInformationCollection
      .withConverter(userInformationConverter.userInformationConverter)
      .add(data);
  },
};

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
  },
  actions: {
  },
  modules: {
  },
});

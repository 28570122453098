<template>
  <div>
    <button @click="$router.go(-1)" class="back-button blue-button">
      <b-icon-chevron-left/> Terug
    </button>
    <div style="height: 100vh;" class="py-5">
      <div class="header-container">
        <h1 class="big-title">Privacy Policy</h1>
      </div>

      <div class="body-container text-center">
        <div class="policy-header d-flex flex-column justify-content-between">
          <img src="@/assets/privacy-policy/kieswijsanalyse_logo.png" alt="Kieswijs Analyse Logo"/>
          <div class="info-box">
            <p>Datum: 23-05-2019</p>
            <p>Versie: 1.0</p>
          </div>
        </div>

        <div class="policy-body">
          <h1>PRIVACYVERKLARING KIESWIJS</h1>
          Je privacy is voor Kieswijs van groot belang. Wij houden ons dan
          ook aan de privacywet. Dit betekent dat je gegevens veilig bij
          ons zijn en dat wij ze altijd netjes gebruiken. In deze privacyverklaring
          leggen we uit wat we op de Kieswijs website en portal (
          <a href="www.kieswijs.nl">www.kieswijs.nl</a> en
          <a href="kwa.kieswijs.nl">kwa.kieswijs.nl</a>) allemaal doen met
          informatie die we over jou te weten komen.
          <br/>

          <h2>1) Verwerkte persoonsgegevens</h2>
          Bij het gebruiken van onze website/portal krijgen wij de volgende
          informatie over jou:
          <br/>
          <ul>
            <li>je naam</li>
            <li>je adres</li>
            <li>je telefoonnummer</li>
            <li>je factuuradres</li>
            <li>je e-mailadres</li>
            <li>je geslacht</li>
            <li>je (profiel-) foto</li>
            <li>je geboortedatum</li>
            <li>je IP-adres</li>
            <li>woonsituatie</li>
            <li>gezinssituatie</li>
            <li>uitslag cito/nio</li>
            <li>achtergrondinformatie van belang voor de studiekeuze</li>
            <li>opleiding</li>
            <li>school</li>
            <li>profielkeuze</li>
            <li>schoolvakken</li>
            <li>hobbies</li>
            <li>weekendinvulling</li>
            <li>de levensles die je hebt meegekregen</li>
            <li>de laatste film die je hebt gezien</li>
            <li>koppelingen met social media</li>
            <li>
              aan jou bij registratie verstrekte gegevens
              zoals je gebruikersnaam en je wachtwoord
            </li>
          </ul>
          <br/>

          De wet noemt dit persoonsgegevens en zegt dat we die alleen
          mogen gebruiken als we precies uitleggen waarom en met welke
          reden. Dit lees je hieronder.
          Wij geven je persoonsgegevens niet aan andere bedrijven of instanties,
          als de wet zegt dat dat moet. Een voorbeeld is dat we bij een
          vermoeden van fraude aangifte mogen doen bij de politie.
          <br/>

          <h2>2) Doeleinde verwerking persoonsgegevens</h2>
          Je gegevens kunnen we gebruiken voor de volgende doeleinden:
          <br/>
          <ul>
            <li>om je de overeengekomen diensten en informatie te leveren</li>
            <li>om je te informeren over huidige en toekomstige diensten van Kieswijs</li>
            <li>om zinvolle informatie te sturen mbt opleidingen, studies, werk</li>
            <li>om te voldoen aan wet- en regelgeving</li>
            <li>
              om je toegang te verlenen tot onze websites en apps,
              al dan niet op basis van je registratie als gebruiker
            </li>
            <li>om contact met je op te nemen over de door jou gevraagde diensten</li>
            <li>om marktonderzoek uit te voeren</li>
            <li>om anoniem dataonderzoek te doen</li>
          </ul>
          <br/>
          Voor zover voor deze verwerkingen je toestemming is vereist zal Kieswijs
          je gegevens alleen verwerken als je die toestemming hebt gegeven.
          <br/>

          <h2>3) Je account</h2>
          Bij bepaalde onderdelen van onze website/portal moet je je eerst registreren.
          Je moet dan informatie over jezelf opgeven. Daarmee maken wij een account,
          waarop je kunt inloggen met je e-mailadres en een wachtwoord. Wij bewaren deze
          informatie zodat je ze niet steeds opnieuw in hoeft te vullen en zodat we je
          makkelijker kunnen benaderen als dat nodig is bij de website/portal.
          <br/>

          <h2>4) Toegang portal</h2>
          Met ons portaal krijg je toegang tot een beheeromgeving waar je de Kieswijsanalyse
          kunt maken. Wij houden bij wat je gedaan hebt en wanneer, zodat daar bewijs van is.
          <br/>

          <h2>5) Contactformulier</h2>
          Met het contactformulier kun je ons vragen stellen of aanvragen doen.
          Daarbij moet je bepaalde informatie invullen. Deze bewaren wij tot we
          zeker weten dat je tevreden bent met onze reactie en zes maanden daarna.
          Zo kunnen we bij vervolgvragen de informatie er makkelijk bij pakken.
          <br/>

          <h2>6) Publicatie op internet</h2>
          Let op: als je toestemming geeft om een evaluatie te geven die op
          onze website gebruikt, kunnen de volgende persoonsgegevens op internet komen:
          <br/>

          <ul>
            <li>je voornaam</li>
            <li>je (profiel-) foto</li>
            <li>door je zelf opgegeven informatie</li>
          </ul>
          <br/>
          Publicatie betekent dat iedereen deze kan lezen en zelfs bewaren.
          Wij hebben daar dan geen controle op. Je kiest zelf welke informatie je
          publiceert. Denk goed na daarover, want het is mogelijk dat je hiermee te
          achterhalen bent.
          <br/>

          <h2>7) Bewerkers</h2>
          Voor zover Kieswijs voor de in dit Privacy- en cookiebeleid vermelde
          doeleinden gebruik maakt van de diensten van derden en deze derden bij
          het uitvoeren van de betreffende diensten toegang hebben tot
          persoonsgegevens heeft Kieswijs de vereiste contractuele en
          organisatorische maatregelen getroffen om te verzekeren dat
          je gegevens uitsluitend voor de hier omschreven doeleinden worden
          verwerkt.
          <br/>

          <h2>8) Cookies</h2>
          Voor het verzamelen van gegevens gebruikt Kieswijs cookies. Een cookie
          is een eenvoudig klein bestandje dat met pagina’s van deze website
          wordt meegestuurd en door je browser op je harde schrijf van je
          computer of mobiele telefoon wordt opgeslagen. De daarin opgeslagen
          informatie kan bij een volgend bezoek weer naar onze servers
          teruggestuurd worden.
          <br/>

          Bij je bezoek aan onze website word je gevraagd toestemming te
          geven voor het gebruik van cookies. Indien je die toestemming
          geeft (door op Akkoord te klikken of door verder te gaan met je
          bezoek aan de website) kunnen de hieronder onder A. tot en met F.
          beschreven cookies worden geplaatst. Indien je geen toestemming
          geeft kunnen alleen functionele cookies worden geplaatst. Mogelijk
          kan je dan niet van alle onderdelen van de website gebruik maken. Wil
          je geen cookies accepteren, dan kan je dit altijd zelf aangeven in je
          browser. Meer informatie hierover kan je verkrijgen bij de fabrikant
          van je browser. Als je cookies uitschakelt, is het mogelijk dat
          bepaalde functies niet werken of dat bepaalde diensten niet beschikbaar
          voor je zijn.
          <br/>

          Via deze website kunnen de volgende soorten cookies geplaatst worden:
          <br/>

          <ol type="A">
            <li>
              <em>Gebruik van permanente cookies</em><br/>
              Met behulp van een permanente cookie kunnen wij jou herkennen
              bij een nieuw bezoek op onze website. De website kan daardoor
              speciaal op jouw voorkeuren worden ingesteld. Ook wanneer je
              toestemming hebt gegeven voor het plaatsen van cookies kunnen
              wij dit door middel van een cookie onthouden. Hierdoor hoef
              je niet steeds jouw voorkeuren te herhalen waardoor je dus
              tijd bespaart en een prettiger gebruik van onze website kunt
              maken. Permanente cookies kan je verwijderen via de
              instellingen van jouw browser.
            </li>
            <li>
              <em>Gebruik van sessie cookies</em><br/>
              Met behulp van een sessie cookie kunnen wij zien welke
              onderdelen van de website je met dit bezoek hebt bekeken.
              Wij kunnen onze dienst daardoor zoveel mogelijk aanpassen op
              het surfgedrag van onze bezoekers. Deze cookies worden
              automatisch verwijderd zodra je jouw webbrowser afsluit.
            </li>
            <li>
              <em>Tracking cookies van onszelf</em><br/>
              Met jouw toestemming plaatsen wij een cookie op jouw apparatuur,
              welke kan worden opgevraagd zodra je een website uit ons netwerk
              bezoekt. Hierdoor kunnen wij te weten komen dat je naast onze
              website ook op de betreffende andere website(s) uit ons netwerk
              bent geweest. Het daardoor opgebouwde profiel is niet gekoppeld
              aan jouw naam, adres, e-mailadres en dergelijke, maar dient
              alleen om advertenties af te stemmen op jouw profiel, zodat
              deze zo veel mogelijk relevant voor je zijn.
            </li>
            <li>
              <em>Tracking cookies van onze adverteerders</em><br/>
              Met jouw toestemming plaatsen onze adverteerders tracking
              cookies op jouw apparatuur. Deze cookies gebruiken zij
              om bij te houden welke pagina’s je bezoekt uit hun netwerk,
              om zo een profiel op te bouwen van jouw online surfgedrag.
              Dit profiel wordt mede opgebouwd op basis van vergelijkbare
              informatie die zij van jouw bezoek aan andere websites uit
              hun netwerk krijgen. Dit profiel wordt niet gekoppeld aan
              jouw naam, adres, e-mailadres en dergelijke zoals bij ons
              bekend, maar dient alleen om advertenties af te
              stemmen op jouw profiel zodat deze zo veel mogelijk
              relevant voor je zijn.
            </li>
            <li>
              <em>Google Analytics</em><br/>
              Via onze website wordt een cookie geplaatst van het
              Amerikaanse bedrijf Google, als deel van de Analyticsdienst.
              Wij gebruikendeze dienst om bij te houden en rapportages
              te krijgen over hoe bezoekers de website gebruiken.
              Google kan deze informatie aan derden verschaffen indien
              Google hiertoe wettelijk wordt verplicht, of voor zover
              derden de informatie namens Google verwerken. Wij hebben
              hier geen invloed op. Wij hebben Google wel toegestaan
              de verkregen analytics informatie te gebruiken voor
              andere Google diensten.
              De informatie die Google verzamelt wordt zo veel mogelijk
              geanonimiseerd. Je IP-adres wordt nadrukkelijk niet meegegeven.
              De informatie wordt overgebracht naar en door Google opgeslagen
              op servers in de Verenigde Staten. Google stelt zich te houden
              aan de Privacy Shield principles en is aangesloten bij het Privacy
              Shield-programma van het Amerikaanse Ministerie van Handel.
              Dit houdt in dat er sprake is van een passend beschermingsniveau
              voor de verwerking van eventuele persoonsgegevens.
            </li>

            <li>
              <em>Social media</em><br/>
              Op onze website zijn buttons opgenomen om webpagina’s te kunnen
              promoten (liken) of delen (tweeten) op sociale netwerken als
              Facebook en Twitter. Deze buttons werken door middel van stukjes
              code die van Facebook respectievelijk Twitter zelf afkomstig
              zijn. Door middel van deze code worden cookies geplaatst. Wij
              hebben daar geen invloed op. Lees de privacyverklaring van Facebook
              respectievelijk van Twitter (welke regelmatig kunnen wijzigen) om te
              lezen wat zij met je (persoons)gegevens doen die zij via deze cookies
              verwerken.
              De informatie die ze verzamelen wordt zo veel mogelijk geanonimiseerd.
              De informatie wordt overgebracht naar en door Twitter, Facebook, Google +
              en LinkedIn opgeslagen op servers in de Verenigde Staten. LinkedIn,
              Twitter, Facebook en Google + stellen zich te houden aan de Privacy
              Shield principes en zijn aangesloten bij het Privacy Shield-programma
              van het Amerikaanse Ministerie van Handel. Dit houdt in dat er sprake is
              van een passend beschermingsniveau voor de verwerking van eventuele
              persoonsgegevens.
            </li>

          </ol>

          <h2>9) Beveiliging</h2>
          Beveiliging van persoonsgegevens is voor ons van groot belang.
          Om je privacy te beschermen, nemen wij de volgende maatregelen:
          <br/>
          <ul>
            <li>
              Toegang tot persoonsgegevens wordt afgeschermd met een
              gebruikersnaam/e-mailadres en wachtwoord
            </li>
            <li>
              Toegang tot persoonsgegevens wordt afgeschermd met een
              gebruikersnaam/e-mailadres en een login token
            </li>
            <li>
              Wij maken gebruik van beveiligde verbindingen (Secure Sockets
              Layer of SSL) waarmee alle informatie tussen jou en onze website
              wordt afgeschermd wanneer je persoonsgegevens invoert
            </li>
            <li>
              Wij houden logs bij van alle opvragingen van persoonsgegevens
            </li>
          </ul>
          <br/>
          Daarnaast hebben wij uiteraard passende technische en organisatorische
          maatregelen getroffen om je persoonsgegevens te beschermen tegen verlies of
          enige andere vorm van onrechtmatige verwerking.
          Wij bewaren je gegevens niet langer dan wettelijk is toegestaan, wettelijk
          verplicht is en/of noodzakelijk is voor de doeleinden waarvoor de gegevens
          worden verwerkt. Hoe lang bepaalde gegevens worden bewaard is afhankelijk
          van de aard van de gegevens en de doeleinden waarvoor zij worden verwerkt.
          De bewaartermijn kan dus per doel verschillen.
          <br/>

          <h2>10) Inzage en wijzigen of verwijderen van je gegevens</h2>
          Als je vragen hebt of wilt weten welke persoonsgegevens wij van jou hebben,
          kun je altijd contact met ons opnemen. Zie de contactgegevens hieronder.
          <br/><br/>

          Je hebt de volgende rechten:
          <br/>
          <ul>
            <li>
              uitleg krijgen over welke persoonsgegevens
              we hebben en wat we daarmee doen
            </li>
            <li>
              inzage in de precieze persoonsgegevens die we hebben
            </li>
            <li>
              het laten corrigeren van fouten
            </li>
            <li>
              het laten verwijderen van (verouderde) persoonsgegevens
            </li>
            <li>
              intrekken van toestemming
            </li>
            <li>
              bezwaar maken tegen een bepaald gebruik
            </li>
          </ul>

          Let op dat je altijd duidelijk aangeeft wie je bent, zodat we zeker weten
          dat we geen gegevens van de verkeerde persoon aanpassen of verwijderen. Om
          misbruik te voorkomen kunnen wij je daarbij vragen om je adequaat te
          identificeren. Wanneer het gaat om inzage in persoonsgegevens gekoppeld
          aan een cookie, dien je een kopie van het cookie in kwestie mee te sturen.
          Je kunt deze terug vinden in de instellingen van je browser.
          <br/>

          <h2>11) Wijzigingen in deze privacyverklaring</h2>
          Wanneer onze website/portal wijzigt, moeten wij natuurlijk ook de
          privacyverklaring aanpassen. Let dus altijd op de datum hierboven
          en kijk regelmatig of er nieuwe versies zijn. Wij zullen ons best
          doen wijzigingen ook apart aan te kondigen.
          <br/>

          <h2>12) Klacht indienen</h2>
          Als je vindt dat wij je niet op de juiste manier helpen, dan heb je
          het recht om een klacht in te dienen bij de toezichthouder. Deze
          heet de Autoriteit Persoonsgegevens.
          <br/>

          <h3>13) Contactgegevens</h3>
          Kieswijs VOF<br/>
          Willemslaan 28<br/>
          1406 LX Bussum<br/>
          info@kieswijs.nl
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrivacyPolice',
};
</script>

<style lang="scss" scoped>
.policy-header {
  width: 100%;
  align-items: center;
  & .info-box {
    text-align: left;
    width: bold;
  }
  & img {
    height: 175px;
    width: auto;
  }
}

.policy-body {
  text-align: left
  & h2 {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.back-button {
  position: fixed;
  top: 0;
  left: 0;
  margin: 1em;
}
</style>

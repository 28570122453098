import { auth } from '@/firebase';
import UserAuth from '@/api/functions/UserAuth';

export default {
  processUserSignIn(context) {
    auth.currentUser.getIdToken(true).then((idToken) => {
      UserAuth.signIn(idToken)
        .then((response) => {
          if (response.status === 200) {
            context.$store.commit('stoppedLoading');
            const currentWindow = window.self;
            currentWindow.close();
          }
        });
    }).catch(() => {
      context.$store.commit('stoppedLoading');
    });
  },
};

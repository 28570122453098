<template>
  <div class="p-5">
    <h4>Verify Email</h4>

    <div v-if="isVerified !== null && isVerified">
      <b-icon-check
        class="py-4"
        style="font-size: 50px; color: #39B74A;"
      />
      <p>Je email is geverifieerd</p>
    </div>

    <div v-if="isVerified !== null && !isVerified">
      <b-icon-x
        class="py-4"
        style="font-size: 50px; color: #C64A3A;"
      />
      <p>Kan je email niet verifieren</p>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase';

export default {
  name: 'CustomEmailHandler',
  props: ['oobCode', 'apiKey', 'continueUrl', 'lang'],
  data: () => {
    return {
      isVerified: null,
    };
  },
  created() {
    if (this.oobCode === undefined) {
      this.isVerified = false;
      return;
    }

    auth.applyActionCode(this.oobCode).then(() => {
      this.isVerified = true;
    }).catch(() => {
      this.isVerified = false;
    });
  },
};
</script>

export default class UserInformation {
  constructor(uid, firstName, lastName,
    firstNameLowercase, lastNameLowercase,
    kieswijsCode, accountType,

    currentEducation, educationYear,
    schoolName, className,
    childKieswijsCodes) {
    this.uid = uid;
    this.firstName = firstName;
    this.lastName = lastName;
    this.firstNameLowercase = firstNameLowercase;
    this.lastNameLowercase = lastNameLowercase;
    this.kieswijsCode = kieswijsCode;
    this.accountType = accountType;

    // Only for Students
    this.currentEducation = currentEducation;
    this.educationYear = educationYear;
    this.schoolName = schoolName;
    this.className = className;

    // Only for Parents
    this.childKieswijsCodes = childKieswijsCodes;
  }
}

<template>
  <div class="p-5">
    <h3 class="mb-4"><strong>Welkom!</strong></h3>
    <h6>Heb je je wachtwoord al opgeslagen? Mogen wij dan wat meer over je weten?</h6>
    <b-form @submit.stop.prevent="onSubmit">
      <!-- <b-form-group>
        <b-form-radio-group
          v-model="form.accountType"
          :options="accountTypeOptions"
          button-variant="secondary"
          buttons
          class="w-100"
        ></b-form-radio-group>
      </b-form-group> -->

      <b-form-group>
        <b-form-input
          name="firstName"
          v-model="$v.form.firstName.$model"
          :state="validateState('firstName')"
          aria-describedby="firstName-feedback"
          placeholder="Voornaam"
          autocomplete="given-name"
          type="text"
        ></b-form-input>

        <b-form-invalid-feedback id="firstName-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-input
          name="lastName"
          v-model="$v.form.lastName.$model"
          :state="validateState('lastName')"
          aria-describedby="lastName-feedback"
          placeholder="Achternaam"
          type="text"
          autocomplete="family-name"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <div>
        <hr/>
        <Parent ref="parentForm" v-if="this.form.accountType === 'parent'"/>
        <Student ref="studentForm" v-if="this.form.accountType === 'student'"/>
      </div>

      <button class="blue-button w-100" type="submit">
        Ga door
      </button>
    </b-form>
  </div>
</template>

<script>
import Parent from '@/components/Parent.vue';
import Student from '@/components/Student.vue';

import {
  required,
} from 'vuelidate/lib/validators';
import userInformationAPI from '@/api/userInformation';
import UserInformation from '@/models/userInformation';
import processUserSignIn from '@/helpers/processUserSignIn';
import { auth } from '@/firebase';

export default {
  name: 'SignUp',
  data: () => {
    return {
      form: {
        accountType: 'student',
        userid: null,
        firstName: null,
        lastName: null,
      },
      // accountTypeOptions: [
      //   { text: 'Scholier/Student', value: 'student' },
      //   { text: 'Ouder/Verzorger', value: 'parent' },
      // ],
    };
  },
  created() {
    this.form.userid = this.$route.params.userid;
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.showNotFilledInToast();
      } else {
        // Check additional information
        if (this.form.accountType === 'student') {
          const studentData = this.$refs.studentForm.getDataIfValidated();

          // Show a message if it is not filled in correctly
          if (studentData === null) {
            this.showNotFilledInToast();
            return;
          }
          this.uploadUserData(studentData);
          return;
        // eslint-disable-next-line no-else-return
        } else if (this.form.accountType === 'parent') {
          const parentData = this.$refs.parentForm.getDataIfValidated();

          // Show a message if it is not filled in correctly
          if (parentData === null) {
            this.showNotFilledInToast();
            return;
          }
          this.uploadUserData(parentData);
          return;
        }

        this.showNotFilledInToast();
      }
    },
    showNotFilledInToast() {
      this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
        toaster: 'b-toaster-bottom-full',
        autoHideDelay: 5000,
        variant: 'danger',
        solid: true,
        appendToast: false,
      });
    },
    cancel() {
      this.$router.push({ name: 'Login' });
    },
    uploadUserData(additionalUserInformation) {
      this.$store.commit('loading');

      // Create a unique code
      const uniqueKieswijsCode = (
        Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
      ).toUpperCase();

      const userInfo = new UserInformation(
        this.form.userid,
        this.form.firstName.trim(),
        this.form.lastName.trim(),
        this.form.firstName.toLowerCase().trim(),
        this.form.lastName.toLowerCase().trim(),
        uniqueKieswijsCode,
        this.form.accountType,
        null,
        null,
        null,
        null,
        null,
      );

      // Set the appropriate data
      if (this.form.accountType === 'student') {
        userInfo.currentEducation = additionalUserInformation.currentEducation;
        userInfo.educationYear = additionalUserInformation.educationYear;
        userInfo.schoolName = additionalUserInformation.schoolName;
        userInfo.className = additionalUserInformation.className;
      } else if (this.form.accountType === 'parent') {
        userInfo.childKieswijsCodes = additionalUserInformation.childrenchildKieswijsCodes;
      }

      const user = auth.currentUser;

      user.updateProfile({
        displayName: `${this.form.firstName} ${this.form.lastName}`,
      }).then(() => {
        // Update user information
        userInformationAPI.create(userInfo)
          .then(() => {
            processUserSignIn
              .processUserSignIn(this);
            this.$store.commit('stoppedLoading');
          })
          .catch((error) => {
            this.$bvToast.toast(`[user info update] ${error.message}`, {
              toaster: 'b-toaster-bottom-full',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              appendToast: false,
            });
            this.$store.commit('stoppedLoading');
          });
      })
        .catch((error) => {
          this.$bvToast.toast(`[user update profile] ${error.message}`, {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false,
          });
          this.$store.commit('stoppedLoading');
        });
    },
  },
  components: {
    Parent,
    Student,
  },
};
</script>

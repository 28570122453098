import UserInformation from '../userInformation';

export default {
  userInformationConverter: {
    toFirestore: (userInformation) => {
      let lowerCaseClassName = userInformation.className;
      if (userInformation.className) {
        lowerCaseClassName = lowerCaseClassName.toLowerCase();
      }

      let lowercaseSchoolName = userInformation.schoolName;
      if (userInformation.schoolName) {
        lowercaseSchoolName = lowercaseSchoolName.toLowerCase();
      }

      return {
        uid: userInformation.uid,
        firstName: userInformation.firstName,
        lastName: userInformation.lastName,
        firstNameLowercase: userInformation.firstNameLowercase,
        lastNameLowercase: userInformation.lastNameLowercase,
        kieswijsCode: userInformation.kieswijsCode,
        currentEducation: userInformation.currentEducation,
        educationYear: userInformation.educationYear,
        schoolName: lowercaseSchoolName,
        className: lowerCaseClassName,
        accountType: userInformation.accountType,

        // Note, this one should only be used in Authentication
        childKieswijsCodes: userInformation.childKieswijsCodes,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new UserInformation(
        data.uid,
        data.firstName,
        data.lastName,
        data.firstNameLowercase,
        data.lastNameLowercase,
        data.kieswijsCode,
        data.accountType,

        data.currentEducation,
        data.educationYear,
        data.schoolName,
        data.className,

        data.childKieswijsCodes,
      );
    },
  },
};

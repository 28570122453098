export default {
  errorMessageTranslator(message) {
    switch (message) {
      case 'The email address is already in use by another account.':
        return 'Dit email adres is al in gebruik door een ander account.';
      default:
        return `Onbekende error (${message})`;
    }
  },
};

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

// firebase init - add your own config here
const configOptions = {
  apiKey: 'AIzaSyAWsAmWeA8Z-xsTwLWlIi283SzEiqac8gc',
  authDomain: 'kieswijs-5e1a6.firebaseapp.com',
  projectId: 'kieswijs-5e1a6',
  storageBucket: 'kieswijs-5e1a6.appspot.com',
  messagingSenderId: '747789705915',
  appId: '1:747789705915:web:e0cf4da72f4c173cb8bf9e',
};

firebase.initializeApp(configOptions);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();
const { firestore } = firebase;
const firebaseStorage = firebase.storage;
const firebaseAuth = firebase.auth;

// collection references
const userInformationCollection = db.collection('user_information');

export {
  db,
  auth,
  firebaseAuth,
  storage,
  firebaseStorage,
  firestore,
  functions,
  userInformationCollection,
};

<template>
  <div>
    <b-form-group>
      <b-form-select
        name="currentEducation"
        v-model="$v.form.currentEducation.$model"
        :options="currentEducationOptions"
        :state="validateState('currentEducation')"
        aria-describedby="currentEducation"
      ></b-form-select>

      <b-form-invalid-feedback id="input-2-live-feedback">
        Dit is een verplicht veld
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="educationYear"
        v-model="$v.form.educationYear.$model"
        :state="validateState('educationYear')"
        aria-describedby="educationYear-feedback"
        placeholder="Schooljaar/Studiejaar"
        type="number"
      ></b-form-input>

      <b-form-invalid-feedback id="educationYear-feedback">
        Dit is een verplicht veld
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="schoolName"
        v-model="$v.form.schoolName.$model"
        :state="validateState('schoolName')"
        aria-describedby="schoolName-feedback"
        placeholder="Naam School/Onderwijsinstelling"
        type="text"
      ></b-form-input>
      <b-form-invalid-feedback id="schoolName-feedback">
        Dit is een verplicht veld
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="className"
        v-model="$v.form.className.$model"
        :state="validateState('className')"
        aria-describedby="className-feedback"
        placeholder="Klas"
        type="text"
        v-if="['vmbo', 'havo', 'vwo'].indexOf(form.currentEducation) > -1"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';

export default {
  name: 'StudentAdditionalUserInformation',
  data: () => {
    return {
      currentEducationOptions: [
        {
          value: null,
          text: 'Huidig onderwijs',
        },
        {
          value: 'vmbo',
          text: 'VMBO',
        },
        {
          value: 'havo',
          text: 'HAVO',
        },
        {
          value: 'vwo',
          text: 'VWO',
        },
        {
          value: 'mbo',
          text: 'MBO',
        },
        {
          value: 'hbo',
          text: 'HBO',
        },
        {
          value: 'wo',
          text: 'WO',
        },
      ],
      form: {
        currentEducation: null,
        educationYear: null,
        schoolName: null,
        className: null,
      },
    };
  },
  validations: {
    form: {
      currentEducation: {
        required,
      },
      educationYear: {
        required,
      },
      schoolName: {
        required,
      },
      className: {
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getDataIfValidated() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return null;
      }
      return this.form;
    },
  },
};
</script>

<template>
  <div class="p-5">
    <h4>Email Herstellen</h4>

    <div v-if="isRestored">
      <b-icon-arrow-counterclockwise
        class="py-4"
        style="font-size: 50px; color: #117195;"
      />
      <p>Je email is hersteld naar {{restoredEmail}}</p>

      <button class="blue-button w-100" @click="sendResetEmail">
        (optioneel) Wachtwoord aanpassen
      </button>
    </div>

    <div v-if="error">
      <b-icon-x
        class="py-4"
        style="font-size: 50px; color: #C64A3A;"
      />
      <p>Er is iets fout gegaan</p>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase';

export default {
  name: 'CustomEmailHandler',
  props: ['oobCode', 'apiKey', 'continueUrl', 'lang'],
  data: () => {
    return {
      isRestored: null,
      restoredEmail: null,
      error: false,
    };
  },
  created() {
    if (this.oobCode === undefined) {
      this.error = true;
      return;
    }

    // Confirm the action code is valid.
    auth.checkActionCode(this.oobCode).then((info) => {
      // Get the restored email address.
      this.restoredEmail = info.data.email;
      // Revert to the old email.
      return auth.applyActionCode(this.oobCode);
    }).then(() => {
      this.isRestored = true;
    })
      .catch(() => {
        this.error = true;
        this.$bvToast.toast('Er is iets fout gegaan met het herstellen', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      });
  },
  methods: {
    sendResetEmail() {
      auth.sendPasswordResetEmail(this.restoredEmail).then(() => {
        this.$bvToast.toast(`Er is een email verstuurd naar ${this.restoredEmail}
          met instructies om het wachtwoord aan te passen.`, {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'info',
          solid: true,
          appendToast: false,
        });
      }).catch(() => {
        this.$bvToast.toast('Wij kunnen op dit moment niet het wachtwoord aanpassen', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      });
    },
  },
};
</script>

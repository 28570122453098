<template>
  <div>
    <transition name="fade">
      <div
        v-if="isLoading"
        class="loading-overlay d-flex flex-column justify-content-center align-items-center">
          <moon-loader color="#F26000"></moon-loader>
          <p class="loading-text mt-2">Laden...</p>
      </div>
    </transition>
    <b-container id="app" class="main-container" fluid>
      <b-row align-v="center">
        <b-col id="left-column" class="d-flex flex-column justify-content-center">
          <router-view/>
          <p>Version: 0.2a</p>
        </b-col>
        <b-col id="right-column" class="d-none d-sm-inline"></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';

export default {
  name: 'App',
  components: {
    MoonLoader,
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
    }),
  },
};
</script>

<style lang="scss">
@import '@/assets/css/main.scss';

#app {
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main-container {
  z-index: 1;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  #right-column {
    background-image: url(./assets/Kieswijs-homeslide-kieswijsanalyse.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
  }
  #left-column {
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
}

.loading-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(white, 0.8);
  z-index: 100;
  & .loading-text {
    color: $orange;
    font-weight: bold !important;
    font-size: 0.7em;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

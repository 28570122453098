<template>
  <div>
    <p>
      In het veld hieronder kunt u de Kieswijs Code invullen van uw kind. Deze code
      kan uw kind vinden op zijn/haar Mijn Kieswijs Dashoard.
    </p>
    <b-form-group>
      <b-form-tags
        name="childrenchildKieswijsCodes"
        input-id="code-tag-pills"
        v-model="$v.form.childrenchildKieswijsCodes.$model"
        tag-variant="secondary"
        tag-pills
        separator=", "
        placeholder="Codes scheiden met een ,"
        :state="validateState('childrenchildKieswijsCodes')"
      ></b-form-tags>

      <b-form-invalid-feedback id="childrenchildKieswijsCodes-feedback">
        Dit is een verplicht veld
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';

export default {
  name: 'Parent',
  data: () => {
    return {
      form: {
        childrenchildKieswijsCodes: [],
      },
    };
  },
  validations: {
    form: {
      childrenchildKieswijsCodes: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getDataIfValidated() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return null;
      }
      return this.form;
    },
  },
};
</script>

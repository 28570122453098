import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import SignUp from '../views/SignUp.vue';
import AdditionalUserInformation from '../views/AdditionalInfoPages/AdditionalUserInformation.vue';
import PasswordForgotten from '../views/PasswordForgotten.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';

import CustomEmailHandler from '../views/CustomEmailHandler.vue';
import RecoverEmail from '../views/CustomHandlers/RecoverEmail.vue';
import ResetPassword from '../views/CustomHandlers/ResetPassword.vue';
import VerifyEmail from '../views/CustomHandlers/VerifyEmail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/extra-informatie/:userid',
    name: 'AdditionalUserInformation',
    component: AdditionalUserInformation,
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'PasswordForgotten',
    component: PasswordForgotten,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/usermgmt',
    name: 'CustomEmailHandler',
    component: CustomEmailHandler,
    children: [
      {
        path: '/email-herstellen',
        name: 'RecoverEmail',
        component: RecoverEmail,
        props: true,
      },
      {
        path: '/wachtwoord-resetten',
        name: 'ResetPassword',
        component: ResetPassword,
        props: true,
      },
      {
        path: '/email-verifieren',
        name: 'VerifyEmail',
        component: VerifyEmail,
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
